import React from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  IconButton,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {} from "react-feather";
import { FaInstagram, FaMedium, FaTelegramPlane } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaRedditAlien } from "react-icons/fa";
import { useHistory, Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  footerSection: {
    background: "#fff",
    borderTop: "1px solid #D6D4D4",
    borderBottom: "1px solid #D6D4D4",
    position: "relative",
    padding: "50px 0px",
    backgroundPosition: " bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(4),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(4),
    },
    // "&"
    "& h5": {
      fontWeight: "bold",
      fontSize: "16px",
      letterSpacing: "2px",
      textTransform: "uppercase",
      color: "#2f086a",
    },
    "& ul": {
      paddingLeft: "0",
    },
    "& p": {
      marginBottom: "0px",
      marginTop: "10px",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "18px",
      color: "#000000",
      width: "100%",
      maxWidth: "340px",
    },
  },
  iconSetting: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "2rem",
    flexWrap: "wrap",
  },
  conrnerBox: {
    width: "35px",
    height: "35px",
    background: "#BA3885",
    borderRadius: "7px",
    color: "#fff",
    padding: 10,
    "&:hover": { background: "#BA3885" },
    [theme.breakpoints.down("md")]: {
      width: "35px",
      height: "35px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "45px",
      height: "45px",
    },
  },
  socialMediaIcon: {
    fontSize: "24px",
    [theme.breakpoints.down("md")]: {
      fontSize: "19px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "17px",
    },
  },
  boxfooter: {
    paddingBottom: "30px",
  },
  imageBox: {
    border: "0.5px solid #D3D3D3",
    boxSizing: "border-box",
    marginTop: "-14px",
    minHeight: "94px",
    minWidth: "94px",
    position: "absolute",
    right: "26px",
    textAlign: "center",
    background: "rgba(0, 0, 0, 0.2)",
    borderRadius: "15px",
    // "@media(max-width: 380px)": {
    //   minWidth: "75px",
    //   minHeight: "75px",
    //   right: "7px",
    // },
  },
  BabyCornerBoxText: {
    // paddingTop: "30px",
    "& h2": {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "30px",
      lineHeight: "37px",
      textTransform: "capitalize",
      color: "#000",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
      "@media(max-width:380px)": {
        fontSize: "16px",
        marginTop: "55px",
      },
    },
    "& h6": {
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "17px",
      textTransform: "capitalize",
      color: "#000",
    },
  },
  footerfeature: {
    "& h4": {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "17px",
      textTransform: "capitalize",
      color: "#3B0D60",
      paddingTop: "5px",
    },
    "& h6": {
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "17px",
      textTransform: "capitalize",
      color: "#000000",
      paddingTop: "10px",
      cursor: "pointer",
      "@media(max-width:375px)": {
        fontSize: "11px",
      },
    },
  },
  withtext: {
    paddingTop: "10px",
    "@media(max-width:375px)": {
      width: "100%",
    },
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <>
      <Box
        className={classes.footerSection}
        style={{ backgroundImage: "url('./images/footerIMG.png')" }}
      >
        <Box
          style={{ margin: "20px 10px 0", position: "relative", zIndex: "2" }}
        >
          <Container>
            <Grid container justify="space-around" spacing={2}>
              <Grid item lg={4} md={3} sm={12} xs={12}>
                <Box>
                  <img
                    src="images/footerlogo.png"
                    alt=""
                    style={{ width: "217px" }}
                  />
                  <Typography variant="body2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac
                    non tincidunt placerat quam arcu suspendisse diam feugiat
                    consectetur.{" "}
                  </Typography>
                  <Typography variant="body1">
                    Mixed Reality © copyright 2022 • All rights reserved.{" "}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={2} md={3} sm={6} xs={6}>
                <Box className={classes.footerfeature}>
                  <Typography variant="h4">Marketplace</Typography>
                  <Link to={"/auction"}>
                    <Typography variant="h6">Explore</Typography>
                  </Link>
                  {/* <Typography variant="h6">How it works</Typography> */}
                  <Typography
                    variant="h6"
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push("/support")}
                  >
                    Support
                  </Typography>
                  <Typography
                    variant="h6"
                    onClick={() => history.push("/partner")}
                  >
                    Become a partner
                  </Typography>
                </Box>
              </Grid>

              <Grid item lg={2} md={3} sm={6} xs={6}>
                <Box className={classes.footerfeature}>
                  <Typography variant="h4">General</Typography>
                  <Typography variant="h6" onClick={() => history.push("/faq")}>
                    Help Center
                  </Typography>
                  <Typography
                    variant="h6"
                    onClick={() => history.push("/term")}
                  >
                    Terms of Service
                  </Typography>
                  <Typography
                    variant="h6"
                    onClick={() => history.push("/privacy")}
                  >
                    Privacy Policy
                  </Typography>
                  <Typography
                    variant="h6"
                    onClick={() => history.push("/seller-buyer-privacy")}
                  >
                    Seller Buyer Policy
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={3} sm={12} xs={12}>
                <Box className={classes.boxfooter}>
                  <Box className={classes.BabyCornerBoxText}>
                    <Typography variant="h2">Join our community</Typography>
                    <Box>
                      <Typography variant="h6" className={classes.withtext}>
                        Become an invaluable part of our community, where you
                        can connect with the core Team and get more details
                        about Nafty. Join us and together let’s change the adult
                        industry for the better!
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={classes.iconSetting}>
                    <Box>
                      <a href={`https://telegram.org/`} target="_blank">
                        <IconButton className={classes.conrnerBox}>
                          <FaTelegramPlane
                            className={classes.socialMediaIcon}
                          />
                        </IconButton>
                      </a>
                    </Box>{" "}
                    &nbsp;&nbsp;
                    <Box>
                      <a href={`https://medium.com/`} target="_blank">
                        <IconButton className={classes.conrnerBox}>
                          <FaMedium className={classes.socialMediaIcon} />
                        </IconButton>
                      </a>
                    </Box>{" "}
                    &nbsp;&nbsp;
                    <Box>
                      <a href={`https://twitter.com/`} target="_blank">
                        <IconButton className={classes.conrnerBox}>
                          <FaTwitter className={classes.socialMediaIcon} />
                        </IconButton>
                      </a>
                    </Box>{" "}
                    &nbsp;&nbsp;
                    <Box>
                      <a href={`https://www.instagram.com/`} target="_blank">
                        <IconButton className={classes.conrnerBox}>
                          <FaInstagram className={classes.socialMediaIcon} />
                        </IconButton>
                      </a>
                    </Box>{" "}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
}
